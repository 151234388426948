/* .SplitPane.vertical {
  height: calc(100vh - 42px) !important;
} */

.app {
  height: 100vh;
}

.app .card {
  margin: 15px;
}

.app .card-body {
  display: flex;
  flex-direction: column;
}
