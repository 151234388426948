.split-horizontal {
  display: flex;
  height: calc(100% - 42px);
}

.split-vertical {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.gutter {
  background: #000;
  opacity: 0.8;
  z-index: 1;
  box-sizing: border-box;
  background-clip: padding-box;
}

.gutter:hover {
  transition: all 1s ease;
}

.gutter.gutter-vertical {
  height: 1px !important;
  margin: 0;
  border-top: 5px solid rgba(0, 0, 0, 0.3);
  border-bottom: 5px solid rgba(0, 0, 0, 0.3);
  cursor: row-resize;
  width: 100%;
}

.gutter.gutter-vertical:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.7);
  border-bottom: 5px solid rgba(0, 0, 0, 0.7);
}

.gutter.gutter-horizontal {
  margin: 0;
  border-left: 5px solid rgba(0, 0, 0, 0.3);
  border-right: 5px solid rgba(0, 0, 0, 0.3);
  cursor: col-resize;
  width: 1px !important;
}

.gutter.gutter-horizontal:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.7);
  border-right: 5px solid rgba(0, 0, 0, 0.7);
}
.gutter.disabled {
  cursor: not-allowed;
}
.gutter.disabled:hover {
  border-color: transparent;
}
